import React from 'react'
import { useState } from 'react';

import { navigate } from "gatsby"
import { Stack, Typography, Box, Checkbox, FormControlLabel } from '@mui/material';

import translateManager from "../translations/translateManager"

import Layout from '../components/Layout'
import AppCard from '../components/AppCard'

import { listVersions } from "../data"

export default function Haccp2023() {
    const item = listVersions.haccp2023;

    const [accepted, setAccepted] = useState(false);

    const onCheckboxClick = (e) => {
        console.log('checkbox click');
        setAccepted(e.target.checked);
    }
    
    let dlContent = ( <AppCard disabled={!accepted} appData={item} standalone={true} isHideOtherVersions={true} /> );

    return (
        <Layout>
            <Typography style={{margin: "1em 0 .5em 0", fontFamily:"Source Serif Pro semi-bold", fontSize: "3em"}} align="center" variant="string" component="div">{translateManager.getText("HACCP_NEW_TITLE")}</Typography>
            <Typography style={{margin: "1em 0 .5em 0", fontFamily:"Source Serif Pro semi-bold", fontSize: "1em"}} align="center" variant="string" component="div">{translateManager.getText("HACCP_NEW_SUBTITLE")}</Typography>

            <Typography style={{margin: "1em 0 .5em 0", fontFamily:"Source Serif Pro semi-bold", fontSize: "2em"}} variant="string" component="div">{translateManager.getText("HACCP_NEW_WARNING")}</Typography>
            <Typography style={{margin: "1em 0 .5em 0", fontFamily:"Source Serif Pro semi-bold", fontSize: "1em"}} variant="string" component="div">{translateManager.getText("HACCP_NEW_TEXT1")}</Typography>
            <Typography style={{margin: "1em 0 .5em 0", fontFamily:"Source Serif Pro semi-bold", fontSize: "1em"}} variant="string" component="div">{translateManager.getText("HACCP_NEW_TEXT2")}<Box fontWeight='bold' display='inline'>{translateManager.getText("HACCP_NEW_TEXT3")}</Box>{translateManager.getText("HACCP_NEW_TEXT4")}</Typography>

            <Typography style={{margin: "1em 0 .5em 0", fontFamily:"Source Serif Pro semi-bold", fontSize: "1em", color: "#AD0900"}} variant="string" component="div">{translateManager.getText("HACCP_NEW_TEXT5")}</Typography>


            <FormControlLabel control={<Checkbox onClick={(e) => { onCheckboxClick(e) }}  />} label={translateManager.getText("HACCP_NEW_CB_ACCEPT_LABEL")} />
                    
            {dlContent}

            <Stack 
                style={{margin: "1em 0"}}
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center">

                <Typography variant="string" style={{margin: "4em 0 0 0", cursor: "pointer", fontFamily: "Lexand Deca bold", fontSize: "0.9em"}} component="div" onClick={() => { navigate("/")} }>{translateManager.getText("BACK_BTN")}</Typography>

            </Stack>
        </Layout>
    )
}
